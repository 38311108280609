@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

::selection {
  background: #5221b5 !important;
  color: #fff;
}

body {
  overflow-x: hidden;
}

.main {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #5221b5, #17032e);
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 5rem;
}

.nvbr {
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.82);
}

.dark-section:nth-child(odd) {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #131313;
  color: white;
}
.dark-section:nth-child(even) {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #1c1c1c;
  color: white;
}

footer {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.82);
  color: white;
  text-align: center;
  width: 100%;
}
.form-control, .form-control:focus, .form-control:hover, .form-control:active, .form-control:-webkit-autofill {
  background-color: transparent !important;
  color: white;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid white;
}
.form-control::placeholder {
  color: #9e9e9e;
}

.form-label {
  color: white;
}
a {
  color: white;
  text-decoration: none;
}

a > i {
  color: #794fcc;
}

a:hover {
  text-decoration: underline;
  color: white;
}

.text-primary {
  color: #a37dec !important;
}

.btn-primary {
  background-color: #a37dec !important;
  border-color: #794fcc !important;
}
.pr-img {
  height: 40px;
  border-radius: 15%;
}

.oswald {
  font-family: "Oswald", serif;
}
.brackets {
  animation: blink 0.5s infinite alternate;
}

@keyframes blink {
  0% { opacity: 1; }
  100% { opacity: 0.3; }
}

